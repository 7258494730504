import React from "react"
import Seo from "../components/SEO"

const Error = () => {
  return (
    <>
      <Seo title="404 Error" description="Boohooo, I don't have this page!" />
      <main className="h-screen bg-whisper">
        <section>
          <h1 className="h-1/2 text-tomato bg-whisper text-9xl p-10 ">
            Page not found
          </h1>
          <h3 className="h-1/2 text-whisper bg-tomato text-4xl p-10">
            Sorry - the page you are looking for is nowhere to be found :(
          </h3>
        </section>
      </main>
    </>
  )
}

export default Error
